import React, { useContext, useCallback, useState } from "react"
import AppTitle from "../../components/appTitle/AppTitle"
import PageWrapper from "../../components/PageWrapper"
import { GlobalStateContext } from "../../context/GlobalContextProvider"
import { formatCurrency } from "../../util/finance"
import ServicesUsageGraph from "../../components/servicesUsageGraph/ServicesUsageGraph"

import * as usageStyles from "./usage.module.css"

import {
  getTenancyUsage as apiGetTenancyUsage
} from "../../services/tenantsApiV2"

import Typography from "@material-ui/core/Typography"
import Frames from "../../components/frames/Frames"
import Frame from "../../components/frame/Frame"


export default ({ location, tenancyId }) => {
  const context = useContext(GlobalStateContext)

  const [tenancy, storeTenancy] = useState(null)
  const [gettingTenancyDetails, setGettingTenancyDetails] = useState(true)
  const [contractServiceUsages, storeContractServiceUsages] = useState(null)
  const [bills, setBills] = useState()

  const getTenancyDetails = useCallback(async () => {
    try {
      setGettingTenancyDetails(true)
      const data = await Promise.all([
        apiGetTenancyUsage(tenancyId)
      ])
      if (data[0] && data[0].value) {
        storeTenancy(data[0].value.tenancy)
        storeContractServiceUsages(data[0].value.contractServiceUsages)
        const { totalElectricBill,
          totalGasBill,
          totalWaterBill,
          totalBroadbandBill,
          totalCanopyBill,
          totalSky,
          totalSubscriptionFees,
          totalTVLBill,
          totalOtherBills,
          lastReconDate
        } = data[0].value
        setBills({
          totalElectricBill,
          totalGasBill,
          totalWaterBill,
          totalBroadbandBill,
          totalCanopyBill,
          totalSky,
          totalSubscriptionFees,
          totalTVLBill,
          totalOtherBills,
          lastReconDate: lastReconDate ? new Date(lastReconDate).toLocaleDateString("en-GB") : null
        })
      }
    } catch (e) {
      console.log(e)
    }
    setGettingTenancyDetails(false)
  }, [tenancyId])

  React.useEffect(() => {
    getTenancyDetails()
  }, [context.loggedIn])

  return (
    <PageWrapper
      location={location}
      showSidebar={true}
      showPortalHeader={true}
      hideHeader={true}
      showLoadingIndicator={gettingTenancyDetails}
      showLoadingLogo={true}
      showLoadingText="Getting your live data"
      tenancy={tenancy}
    >
      {context.tenantUser && (
        <div className={usageStyles.usage}>
          <AppTitle title={"Your account bills!"} color={"red"} />
          {tenancy && !tenancy.unlimited && process.env.GATSBY_USAGE_MAINTENANCE_MESSAGE && <div>
            <Frame title="Maintenance Work">
              <div>
                <div>
                  <p style={{ fontFamily: "tenon, sans-serif", fontsize: "40px", fontWeight: "bold", color: "#FF8469" }}>
                    Your bills and usage services will be offline due to maintenance work. We apologise for any inconvenience caused.
                  </p>
                </div>
              </div>
            </Frame>
          </div>}
          {tenancy && !tenancy.unlimited && !process.env.GATSBY_USAGE_MAINTENANCE_MESSAGE && <div>
            <Frame title="Usage Overview">
              <div>
                <div>
                  <div style={{ fontWeight: "bold" }}>Usage review</div>
                  <p>
                    Every month we review your usage compared to your
                    allowances, to ensure you are not at risk of going over
                    your allowance.
                  </p>
                  <p>
                    If it looks like you're going to over use your
                    allowance, then we will adjust your monthly card payment
                    amount to avoid your account from building up debt with
                    notice.
                  </p>
                  <p>
                    We do not want any of our customers to be under any
                    financial difficulties during this time and therefore
                    would like to make our customers aware if they're
                    overusing. Please ensure you are checking your customer
                    portal and are sending in monthly readings.
                  </p>
                  <p>
                    If you would like help and advice on how to reduce your
                    energy consumption, then please check out our energy
                    saving tips{" "}
                    <a
                      target="_blank"
                      href="https://www.the-bunch.co.uk/5-ways-to-save-energy-this-winter/"
                    >
                      here
                    </a>
                    .
                  </p>
                </div>
              </div>
              <div className={usageStyles.usagePanels}>
                <ServicesUsageGraph
                  tenancyId={tenancyId}
                  type="Energy"
                  data={contractServiceUsages}
                  hideGraph
                />
                <ServicesUsageGraph
                  tenancyId={tenancyId}
                  type="Electric"
                  data={contractServiceUsages}
                  hideGraph
                />
                <ServicesUsageGraph
                  tenancyId={tenancyId}
                  type="Gas"
                  data={contractServiceUsages}
                  hideGraph
                />
              </div>
              <div className={usageStyles.usagePanels}>
                <ServicesUsageGraph
                  tenancyId={tenancyId}
                  type="Water"
                  data={contractServiceUsages}
                  hideGraph
                />
                <ServicesUsageGraph
                  tenancyId={tenancyId}
                  type="Broadband"
                  data={contractServiceUsages}
                  hideGraph
                />
                <ServicesUsageGraph
                  tenancyId={tenancyId}
                  type="TV Licence"
                  data={contractServiceUsages}
                  hideGraph
                />
              </div>

            </Frame>
            <Frames>
              <Frame title="Household Bill Summary" description={bills.lastReconDate ? "Latest Update :   " + bills.lastReconDate : ""}>
                <div style={{ display: "flex", flexDirection: "column", justifyItems: "flex-start", flexWrap: "wrap" }}>
                  <Typography style={{ margin: "5px 0px" }}>This shows the sum of your household's total bills to date, per service, since the start of your contract</Typography>
                  <div style={{ margin: "25px 0px 5px 0px" }}>
                    <Typography className="h6"  >Total Electric Bill :  {formatCurrency(bills.totalElectricBill)}</Typography>
                  </div>
                  <div style={{ margin: "5px 0px" }}>
                    <Typography className="h6"  >Total Gas Bill :  {formatCurrency(bills.totalGasBill)}</Typography>
                  </div>
                  <div style={{ margin: "5px 0px" }}>
                    <Typography className="h6"  >Total Water Bill :  {formatCurrency(bills.totalWaterBill)}</Typography>
                  </div>
                  <div style={{ margin: "5px 0px" }}>
                    <Typography className="h6"  >Total Broadband Bill :  {formatCurrency(bills.totalBroadbandBill)}</Typography>
                  </div>
                  {bills.totalCanopyBill != null &&
                    <div style={{ margin: "5px 0px" }}>
                      <Typography className="h6"  >Total Canopy Bill :   {formatCurrency(bills.totalCanopyBill)}</Typography>
                    </div>
                  }
                  {bills.totalSky != null &&
                    <div style={{ margin: "5px 0px" }}>
                      <Typography className="h6"  >Total Sky Bill :  {formatCurrency(bills.totalSky)}</Typography>
                    </div>
                  }
                  {bills.totalSubscriptionFees != null &&
                    <div style={{ margin: "5px 0px" }}>
                      <Typography className="h6"  >Total Subscription Fees :  {formatCurrency(bills.totalSubscriptionFees)}</Typography>
                    </div>
                  }
                  {bills.totalTVLBill != null &&
                    <div style={{ margin: "5px 0px" }}>
                      <Typography className="h6"  >Total TVL Bill :  {formatCurrency(bills.totalTVLBill)}</Typography>
                    </div>
                  }
                  {bills.totalOtherBills != null &&
                    <div style={{ margin: "5px 0px" }}>
                      <Typography className="h6"  >Total Other Bills :  {formatCurrency(bills.totalOtherBills)}</Typography>
                    </div>
                  }
                </div>
              </Frame>
            </Frames>
          </div>}
        </div>
      )}
    </PageWrapper>
  )
}